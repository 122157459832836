<template>
	<v-container fluid style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('nav.settings') }}</span>
		</div>

		<v-divider class="my-2" />

		<div class="mx-2 my-3">
			<span class="text-h5" v-if="chipSelected == 'SettingsAccount'">{{ $t('settings.account') }}</span>
			<span class="text-h5" v-else-if="chipSelected == 'SettingsCompany'">{{ $t('companies.company') }}</span>
			<span class="text-h5" v-else-if="chipSelected == 'SettingsSubscriptions'">{{ $t('subscriptions.subscriptions') }}</span>
			<span class="text-h5" v-else-if="chipSelected == 'SettingsNotifications'">{{ $t('notifications.notifications') }}</span>
		</div>

		<v-chip-group v-model="chipSelected" column color="sidebarBackground" active-class="sidebarBackground" class="my-3">
			<v-chip value="SettingsAccount" :disabled="chipSelected == 'SettingsAccount'">
				<v-btn text :to="{ name: 'SettingsAccount' }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-account</v-icon>
					{{ $t('settings.account') }}
				</v-btn>
			</v-chip>
			<v-chip value="SettingsCompany" :disabled="chipSelected == 'SettingsCompany'" v-if="setupInfo.joinedCompany">
				<v-btn text :to="{ name: 'SettingsCompany' }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-domain</v-icon>
					{{ $t('companies.company') }}
				</v-btn>
			</v-chip>
			<v-chip value="SettingsSubscriptions" :disabled="chipSelected == 'SettingsSubscriptions'" v-if="setupInfo.joinedCompany">
				<v-btn text :to="{ name: 'SettingsSubscriptions' }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-domain</v-icon>
					{{ $t('subscriptions.subscriptions') }}
				</v-btn>
			</v-chip>
			<v-chip value="SettingsNotifications" :disabled="chipSelected == 'SettingsNotifications'">
				<v-btn text :to="{ name: 'SettingsNotifications' }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-bell-ring</v-icon>
					{{ $t('notifications.notifications') }}
				</v-btn>
			</v-chip>
		</v-chip-group>

		<v-divider class="my-2" />

		<router-view />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('nav.settings')
		}
	},
	data() {
		return {
			chipSelected: 'SettingsAccount'
		}
	},
	computed: {
		...mapGetters({
			setupInfo: 'user/setupInfo'
		})
	},
	created() {
		this.chipSelected = this.$route.name
		this.fetchCurrentUser()
	},
	methods: {
		...mapActions('user', ['fetchCurrentUser'])
	}
}
</script>

<style scoped>
.v-chip--disabled {
	opacity: 1;
}
</style>
